<template>
    <div class="row">
        <div class="col-md-12">
          <h2 class="pb-3">Einstellungen - Parcours</h2>
              <div class="card mb-3" v-for="parc in parcours" :key="parc.key">
                <div class="card-header"><b>{{ parc.parcoursID}}</b></div>
                <div class="card-body">
                  <form @submit.prevent="onUpdateParcoursForm(parc)">
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <label>Bezeichnung des Parcours</label>
                          <div class="row">
                            <div class="col-8 col-md-10">
                              <input type="text" class="form-control" v-model="parc.parcoursID" required inputmode="text">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label>Position</label>
                          <div class="row">
                            <div class="col-3 col-md-3">
                              <input type="number" class="form-control" v-model="parc.pos" required inputmode="number">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <label>Teilnehmer 1. Warnung</label>
                          <div class="row">
                            <div class="col-8 col-md-10">
                              <input type="range" class="custom-range" v-model="parc.attendeeWarning1" min="1" max="99">
                              <small id="rangeHelpBlock" class="form-text text-muted">
                                Ab diesem Wert wird die Anzahl der Teilnehmer in Gelb angezeigt.
                              </small>
                            </div>
                            <div class="col-4 col-md-2 pl-0">
                              <input type="number" class="form-control" v-model="parc.attendeeWarning1" min="1" max="99" inputmode="numeric">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label>Teilnehmer 2. Warnung</label>
                          <div class="row">
                            <div class="col-8 col-md-10">
                              <input type="range" class="custom-range" v-model="parc.attendeeWarning2" min="2" max="100">
                              <small id="rangeHelpBlock" class="form-text text-muted">
                                Ab diesem Wert wird die Anzahl der Teilnehmer in Rot angezeigt.
                              </small>
                            </div>
                            <div class="col-4 col-md-2 pl-0">
                              <input type="number" class="form-control" v-model="parc.attendeeWarning2" min="2" max="100" inputmode="numeric">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row pt-4">
                      <div class="col">
                        <div class="form-group">
                          <label>Wartezeit 1. Warnung</label>
                          <div class="row">
                            <div class="col-8 col-md-10">
                              <input type="range" class="custom-range" v-model="parc.waitWarning1" min="1" max="59">
                              <small id="rangeHelpBlock" class="form-text text-muted">
                                Ab diesem Wert wird die Wartezeit in Gelb angezeigt (in Minuten).
                              </small>
                            </div>
                            <div class="col-4 col-md-2 pl-0">
                              <input type="number" class="form-control" v-model="parc.waitWarning1" min="1" max="59" inputmode="numeric">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label>Wartezeit 2. Warnung</label>
                          <div class="row">
                            <div class="col-8 col-md-10">
                              <input type="range" class="custom-range" v-model="parc.waitWarning2" min="2" max="60">
                              <small id="rangeHelpBlock" class="form-text text-muted">
                                Ab diesem Wert wird die Wartezeit in Rot angezeigt (in Minuten).
                              </small>
                            </div>
                            <div class="col-4 col-md-2 pl-0">
                              <input type="number" class="form-control" v-model="parc.waitWarning2" min="2" max="60" inputmode="numeric">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row pt-4">
                      <div class="col-6">
                        <div class="form-group">
                          <label>Einweisungsdauer</label>
                          <div class="row">
                            <div class="col-8 col-md-10">
                              <input type="range" class="custom-range" v-model="parc.blockDuration" min="1" max="30">
                              <small id="rangeHelpBlock" class="form-text text-muted">
                                Für diese Zeit wird der Parcours als belegt angezeigt (in Minuten).
                              </small>
                            </div>
                            <div class="col-4 col-md-2 pl-0">
                              <input type="number" class="form-control" v-model="parc.blockDuration" min="1" max="30" inputmode="numeric">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="alert alert-danger mt-4" role="alert" v-if="formValidationErrors.length">
                      <b>Bitte die folgenden Fehler beheben:</b>
                      <ul>
                        <li v-for="error in formValidationErrors" :key="error">{{ error }}</li>
                      </ul>
                    </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-11 pr-0">
                      <button class="btn btn-primary btn-block"><i class="bi bi-save"></i> Speichern</button>
                    </div>
                    <div class="col-1 pl-1">
                      <button class="btn btn-danger btn-block" @click.prevent="removeParcours(parc.key)" data-toggle="tooltip" data-placement="top" title="Parcours löschen"><i class="bi bi-trash"></i></button>
                    </div>
                  </div>
                </div>
            </form>
                </div>
              </div>
              <div class="pb-4 pt-2 pl-3">
                <button class="btn btn-primary pl-3 pr-3" @click.prevent="addParcours"><i class="bi bi-plus-square"></i> Parcours hinzufügen</button>
              </div>
            </div>
    </div>
</template>

<style scoped>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
</style>

<script>
import { db } from '../firebaseDb'
import store from "../store"
export default {
  name: "app",
  components: {
  },
  data () {
    return {
      parcours: [],
      formValidationErrors: []
    }
  },
  created () {
    const parcoursRef = db.collection('parcours')
      parcoursRef.where('uid', '==', store.getters.user.data.uid).orderBy('pos').onSnapshot((snapshotChange) => {
      this.parcours = []
      snapshotChange.forEach((doc) => {
        this.parcours.push({
          key: doc.id,
          parcoursID: doc.data().parcoursID,
          attendeeWarning1: doc.data().attendeeWarning1,
          attendeeWarning2: doc.data().attendeeWarning2,
          waitWarning1: doc.data().waitWarning1,
          waitWarning2: doc.data().waitWarning2,
          blockDuration: doc.data().blockDuration,
          pos: doc.data().pos
        })
      })
    })
  },
  methods: {
    onUpdateParcoursForm (parc) {
      this.formValidationErrors = []

      if (parc.parcoursID && parc.attendeeWarning2 && parc.attendeeWarning1 && parc.waitWarning1 && parc.waitWarning2 && parc.blockDuration && parc.attendeeWarning2 > parc.attendeeWarning1 && parc.waitWarning2 > parc.waitWarning1) {
        db.collection('parcours').doc(parc.key)
                .update({ parcoursID: parc.parcoursID, attendeeWarning1: parc.attendeeWarning1, attendeeWarning2: parc.attendeeWarning2, waitWarning1: parc.waitWarning1, waitWarning2: parc.waitWarning2, blockDuration: parc.blockDuration, pos: parc.pos }).then(() => {
                  this.$toast.success("Parcours-Optionen wurden erfolgreich gespeichert.")
                }).catch((error) => {
                  this.$toast.error("Fehler beim Speichern der Parcours-Optionen.")
                  console.log(error)
                })
      } else {
        if (!(parc.attendeeWarning2 > parc.attendeeWarning1)) {
          this.formValidationErrors.push('"Teilnehmer 2. Warnung" muss größer sein als "Teilnehmer 1. Warnung".')
          console.log("TN1 " + parc.attendeeWarning1 + " TN2 " + parc.attendeeWarning2)
        }
        if (!(parc.waitWarning2 > parc.waitWarning1)) {
          this.formValidationErrors.push('"Wartezeit 2. Warnung" muss größer sein als "Wartezeit 1. Warnung".')
        }
      }
    },
    removeParcours: function (parc) {
      if (window.confirm("Diesen Parcours löschen?")) {
        console.log("Remove parcours " + parc)
        db.collection('parcours').doc(parc).delete().then(() => {
          this.$toast.success("Parcours wurde erfolgreich entfernt.")
        }).catch(() => {
          this.$toast.error("Fehler beim Entfernen des Parcours.")
        })
      }
    },
    addParcours () {
      // Find next available parcours ID
      var parcoursID = 'Übungsparcours ' + (this.parcours.length + 1)

      if (parcoursID !== 0) {
        console.log("Add parcours " + parcoursID)
        db.collection('parcours').add({
          attendeeWarning1: 15,
          attendeeWarning2: 20,
          attendees: 0,
          blockDuration: 7,
          blockedUntil: null,
          briefingID: null,
          isBlocked: false,
          isWaiting: false,
          lastTrainer: null,
          parcoursID: parcoursID,
          startWait: null,
          trainer: null,
          waitWarning1: 15,
          waitWarning2: 17,
          pos: 0,
          uid: store.getters.user.data.uid
        }).then(() => {
          this.$toast.success("Parcours wurde erfolgreich hinzugefügt.")
        }).catch(() => {
          this.$toast.error("Fehler beim Hinzufügen des Pracours.")
        })
      } else {
        console.log("No available parcours ID")
        this.$toast.error("Fehler beim Hinzufügen des Pracours.")
      }
    }
  },
  computed: {
}
}

</script>
